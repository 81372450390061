<template>
  <div></div>
</template>

<script>
import { auth } from "../.././firebase";

export default {
  name: "login",
  created() {
    this.logout();
  },

  methods: {
    async logout() {
      await auth.signOut();
      console.log("logged out...");
    },
  },
};
</script>
